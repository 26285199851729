<template>
  <el-card shadow="never">
    <el-row>
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-input v-model="queryInfo.lendOrderCode" class="input" placeholder="借出单号" clearable></el-input>
      <el-button type="primary" @click="query" :loading="loading">查询</el-button>
    </el-row>
    <el-row style="padding-top: 50px">
      <el-form v-model="formData" label-width="200px">
        <el-row>
          <el-form-item label="借出单号：">
            <div class="itemValue">{{ busItem.lendOrderCode }}</div>
          </el-form-item>
        </el-row>
        <el-row class="img-row">
          <el-form-item label="外观检验照片：">
            <ImageUploadComponent url="upload/singleFile" ref="uploadComponent" :file-list="test1ImageList" :img-cnt="3" @imageChange="setTest1ImageIds"></ImageUploadComponent>
          </el-form-item>
        </el-row>
        <el-row class="img-row">
          <el-form-item label="电性能检验照片：">
            <ImageUploadComponent url="upload/singleFile" ref="uploadComponent" :file-list="test2ImageList" :img-cnt="3" @imageChange="setTest2ImageIds"></ImageUploadComponent>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="5" style="text-align: right">
            <el-button type="primary" @click="submit" :loading="loading">保存检验照片</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
  </el-card>
</template>

<script>
import ImageUploadComponent from "@/components/ImageUploadComponent";

export default {
  name: "BusItemImageEdit",
  components: {ImageUploadComponent},
  data() {
    return {
      queryInfo: {
        assetSn: '',
        lendOrderCode: ''
      },
      formData: {
        busItemId: '',
        test1ImageIds: [],
        test2ImageIds: [],
      },
      busItem: {},
      test1ImageList: [],
      test2ImageList: [],
      loading: false
    }
  },
  methods: {
    query() {
      this.reset()
      if (!this.queryInfo.assetSn) {
        return this.$message.error('请输入资产码');
      }

      this.$axios.post('busItemImage/queryImage', this.queryInfo).then(response => {
        this.loading = false
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error('查询失败，' + res.message);
        }
        let arr = res.data.imageList || [];
        this.busItem = res.data.busItem || {};
        this.formData.busItemId = this.busItem.id
        if (arr.length == 0) {
          return this.$message.info("没有检验照片")
        }
        this.test1ImageList = []
        this.test2ImageList = []
        this.formData.test1ImageIds = []
        this.formData.test2ImageIds = []
        for (let i = 0; i < arr.length; i++) {
          let obj = {
            url: arr[i].url,
            imageId: arr[i].fileId,
            old: true,
            uid: Math.floor((Math.random() * 10000000))
          };
          if (arr[i].imageType == 'test1') {
            this.test1ImageList.push(obj)
            this.formData.test1ImageIds.push(obj.imageId)
          } else if (arr[i].imageType == 'test2') {
            this.test2ImageList.push(obj);
            this.formData.test2ImageIds.push(obj.imageId)
          }
        }

      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });

    },
    submit() {
      if (!this.formData.busItemId) {
        return this.$message.error('请先进行查询');
      }
      this.$axios.post('busItemImage/saveImage', this.formData).then(response => {
        this.loading = false
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error('保存失败，' + res.message);
        }
        this.reset()

        this.$message.success('保存成功');
      }, error => {
        this.loading = false;
        return this.$message.error('保存失败，' + error.message);
      });
    },
    setTest1ImageIds(idList) {
      this.formData.test1ImageIds = idList || []
    },
    setTest2ImageIds(idList) {
      this.formData.test2ImageIds = idList || []
    },
    reset() {
      this.formData.test2ImageIds = [];
      this.formData.test1ImageIds = [];
      this.formData.busItemId = '';
      this.test1ImageList = []
      this.test2ImageList = []
      this.busItem = {}
    }
  }
}
</script>

<style scoped>
.input {
  width: 250px;
  margin-right: 10px;
}

.img-row {
  height: 240px;
}

.itemValue {
  color: goldenrod;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>